import Axios from 'axios';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import Chart from "chart.js/auto";
// import * as hiringvalue from './../../services/hiringvalue';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      company: {},
      hiring: {
        tasks: '',
        company: {},
        user: {},
        files: [],
        invoices: [],
        specialities: [],
      },
      community: {
        users: [],
      },
      formSelectProvider: {
        provider: 0,
        decision: 0,
      },
      isPaymentDate: false,
      formInfo: {
        razao: '',
        cnpj: '',
      },
      specialities: [],
      hasReviewedFlag: false,
      syncing: false,

      localizacao: [
        { city: "Total", percent: 100, color: "#74E7D0",number: 10 },
        { city: "1  São Paulo - SP", percent: 72, color: "#74E7D0", number: "72,7%"},
        { city: "2  Belo Horizonte - BH", percent: 18, color: "#74E7D0", number: "18,2%" },
        { city: "3  Salvador - BA", percent: 9, color: "#74E7D0", number: "9,1%" },
      ],

      
      posts: [
        { city: "Total", percent: 100, color: "#7553E2",number: 20 },
        { city: "1  Instagram", percent: 81, color: "#7553E2", number: "81,8%"},
        { city: "2  YouTube", percent: 9, color: "#7553E2", number: "9,1%" },
        { city: "3  Tik Tok", percent: 9, color: "#7553E2", number: "9,1%" },
      ],

      verticais: [
        { city: "1  Viagem",  percent: 72, color: "#F2C94C",number: "72,7%"},
        { city: "2  Lifestyle", percent: 9, color: "#F2C94C", number: "9,1%" },
        { city: "3  Finanças", percent: 9, color: "#F2C94C", number: "9,1%" },
        { city: "4  Vários tópicos", percent: 9, color: "#F2C94C", number: "9,1%" },
      ],

      
      cpv: [
        { city: "1  José Augusto Soares",  percent: 72, color: "#74E7D0"},
        { city: "2  José Augusto Soares", percent: 9, color: "#74E7D0" },
        { city: "3  José Augusto Soares ", percent: 9, color: "#74E7D0" },
        { city: "4  José Augusto Soares ", percent: 9, color: "#74E7D0" },
        { city: "5  José Augusto Soares ", percent: 9, color: "#74E7D0" },
        { city: "6  José Augusto Soares ", percent: 9, color: "#74E7D0" },
      ],


    }
  },

  mounted() {

    this.genderCount();

    this.budget();


   
  },

  computed: {
    // acceptedvalue() {
    //   console.log(hiringvalue, this.hiring);
    //   let aux = hiringvalue.company(this.hiring.company, this.hiring);
    //   return aux;
    // },

    chatUrl() {
      if (this.hiring.status < 3) return '#';
      else return '/dashboard/client/briefing/' + this.hiring.id + '/chat/' + this.hiring.provider_id;
    },

    projectName() {
      return '#' + this.hiring.id + '-' + moment(this.hiring.created_at).format('Y');
    },

    isCompleted() {
      if (this.hiring.status != 4) return false;
      if (moment(this.hiring.date_end).subtract(5, "days") >= moment()) return false;
      // if (!this.hiring.invoices || !this.hiring.invoices.length) return false;
      // this.hiring.invoices.forEach(invoice => {
      //   if (invoice.status != 2) return false;
      // });
      return true;
    },

    canEvaluate() {
      if (this.hiring.status != 4) return false;
      if (moment(this.hiring.date_end) >= moment()) return false;
      if (this.haveReviewed) return false;
      return true;
    },

    haveReviewed() {
      if (!this.hiring.reviews) return false;
      let haveReviewed = this.hiring.reviews.map((e) => { return e.user_id }).indexOf(this.me.id);
      if (haveReviewed > -1) return true;
      if (this.hasReviewedFlag) return true;
      return false;
    },

    reviewDate() {
      if (!this.haveReviewed) return moment();
      let review = {};
      let index = this.hiring.reviews.map((e) => { return e.user_id }).indexOf(this.me.id);
      if (index > -1) review = this.hiring.reviews[index];
      return review.created_at;
    },

    communityIds() {
      var ids = [];
      if (this.community.users.length) ids = this.community.users.map((e) => { return e.id });
      // console.log(ids);
      return ids;
    },
  },

  methods: {
    formatDate(date) {
      return (date) ? moment(date).format('DD/MM/YYYY') : 'Renovação';
    },

    toCurrency(val) {
      let value = val * 1;
      let currency = "BRL";
      if (this.hiring.company.currency == "eur") {
        value = val / this.$store.state.currencies.eur;
        currency = "EUR";
      }
      if (this.hiring.company.currency == "usd") {
        value = val / this.$store.state.currencies.usd;
        currency = "USD";
      }
      return (value / 100).toLocaleString("pt-BR", { style: "currency", currency: currency, minimumFractionDigits: 2 });
    },

    costByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85;
          break;
        case 2:
          value = value / 0.85;
          break;
        case 3:
          value = value / 0.75;
          break;
        case 4:
            value = value / 0.8928571429;
            break;
        case 5:
            value = value;
            break;
        case 6:
            value = value / 0.70;
            break;
        case 7:
            value = value / 0.80;
            break;
        default:
          value = value / 0.85;
      }
      // Pernod Ricard tem 0,6% sobre o valor final
      if (this.hiring.company_id == 159) value = value * 1.006;
      return value;
    },

    approve(ids) {
      // check if user has financial data
      let _this = this;
      if (!this.formInfo.razao || !this.formInfo.cnpj) {
        $('#info-modal').modal('show');
        return;
      }
      var data = {
        providers: ids,
        decision: 1,
      };
      _this.syncing = true;
      axios
        .put('/api/v1/hirings/' + this.hiring.id + '/select-professional', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          _this.syncing = false;
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    giveupContract() {
      var data = {};
      axios
        .put('/api/v1/hirings/' + this.hiring.id + '/giveup-contract', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            $('#cancel-contract-modal').modal('hide');
            this.$router.push({ path: '/dashboard/client' });
          } else {
            console.log(response.data.error);
          }
        });
    },

    evaluate(formData) {
      var data = formData;
      try {
        axios
          .post('/api/v1/reviews?hiring=' + this.hiring.id, data, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            if (!response.data.error) {
              this.$router.push({ path: '/dashboard/client/' });
            } else {
              console.log(response.data.error);
            }
          });
        this.hiring.reviews = [{ ...data, user_id: this.me.id }]
        this.hasReviewedFlag = true
      } catch (err) {
        console.log(err)
      }
    },

    renew(formData) {
      var _this = this;
      var data = {
        date_end: formData.date_end,
        date_start: formData.date_start,
        extra_value: parseInt(formData.value.replace(/\D/g, '')),
        message: formData.message,
      };
      axios
        .post('/api/v1/hirings/' + _this.hiring.id + '/renew', data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.$router.push({ path: '/dashboard/client/' });
          } else {
            console.log(response.data.error);
          }
        });
    },

    submitInfo() {
      let _this = this;
      let formData = new FormData();
      formData.set("razao", _this.formInfo.razao);
      formData.set("cnpj", _this.formInfo.cnpj);

      formData.append('_method', 'PUT');
      axios
        .post('/api/v1/companies/' + _this.formInfo.id, formData, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          // console.log(response.data);
          if (response.data.error) {
            _this.errors = response.data.error;
          } else {
            $('#info-modal').modal('hide');
          }
        });
    },

    editHiring(data) {
      axios
        .put('/api/v1/hirings/' + data.id, data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
          } else {
            console.log(response.data.error);
          }
        });
    },

    async genderCount() {

      const ctx = document.getElementById("genderCount");
      const data = {
        labels: ['Non Binary', 'Men', 'Women', 'Couple', 'Other'],
        datasets: [{
          label: "Contagem de gênero",
          data: [9, 18, 36, 18, 27, 9],
          backgroundColor: ["#FF6A6A", "#74E7D0", "#6D72FF", "#FFDF70", "#FF8DF4", "#FFDF70"],
        }, ],
      };

      const genderCount = new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
          }
        },
      });
      budget;
    },

    async budget() {

      const ctx = document.getElementById("budget");
      const data = {
        labels: ['Trans Intinerante', 'To de Folga', 'Terra Adentro', 'Samir Duarte', 'Andarilhas', 'Bichaa Preta pelo Mundo', 'Esse Mundo é Nosso', 'Fizemos um Rolê', 'Num Pulo'],
        datasets: [{
          label: "Orçamento",
          data: [1, 9, 11, 18, 3, 9, 1, 8, 12, 24],
          backgroundColor: ["#FF6A6A", "#74E7D0", "#6D72FF", "#FF8DF4", "#FFDF70", "#FF984E", "#83CA28", "#FB3AFF", "#9D02D3", "#266DD8"],
        }, ],
      };

      const budget = new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
              padding: '20',
              pointStyle: 'circle',
            },
          }
        },
      });
      budget;
    },

  },

}